import Cookies from "js-cookie";

export function setCookie({name='cookie', value=null, expire=365}){
    Cookies.set(name, value, {expires:expire, path:'/'})
}

export function getCookie(name){
    return Cookies.get(name);
}

export function deleteCookie(name){
    Cookies.remove(name);
}